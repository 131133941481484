import React, { Component } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Arrow from "./Arrow";
import ArrowU from "./ArrowU";
import "../App.css";
import logo from "../images/logo.png";
import Header from "./Header";

export default class Print75 extends Component {
  constructor() {
    super();
    this.state = {
      color: "#ff2dff",
      header: "",
      text: "",
      num: "1",
      bingo: [
        "B",
        "I",
        "N",
        "G",
        "O",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        "*",
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
      ],
    };
  }

  componentDidMount() {
    this.shufflecard();
  }

  shufflecard = () => {
    let tb = [...this.state.bingo];
    for (let i in tb) {
      if (tb[i] === 0) {
        let newnum = 0;
        if (i % 5 === 0) {
          do {
            newnum = Math.ceil(Math.random() * 15);
          } while (tb.includes(newnum));
        } else if (i % 5 === 1) {
          do {
            newnum = Math.ceil(Math.random() * 15) + 15;
          } while (tb.includes(newnum));
        } else if (i % 5 === 2) {
          do {
            newnum = Math.ceil(Math.random() * 15) + 30;
          } while (tb.includes(newnum));
        } else if (i % 5 === 3) {
          do {
            newnum = Math.ceil(Math.random() * 15) + 45;
          } while (tb.includes(newnum));
        } else {
          do {
            newnum = Math.ceil(Math.random() * 15) + 60;
          } while (tb.includes(newnum));
        }
        tb[i] = newnum;
      }
    }
    this.setState({ bingo: [...tb] });
    return tb;
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleColor = (e) => {
    console.log(e.target.id);
    this.setState({ color: e.target.id });
  };

  handlePrint = () => {
    console.log("print");
    let header = this.state.header || "75 Ball Bingo";
    let mycolor = this.state.color;
    let nums = this.state.num;
    if (nums < 1) nums = 1;
    let innerhtml = `<div>`;
    let shuffle = [...this.state.bingo];
    for (let i = 0; i < nums; i++) {
      //for each page fill with 6 of each bingo
      if (i === nums - 1) {
        innerhtml += `<div style="padding-left:90px;padding-top:50px;padding-bottom:40px;width:970;"><p style="border:2px solid black;text-align:center;line-height:50px;width:790px;height:50px;background-color:${mycolor};"><strong>${header}</strong></p>`;
      } else {
        innerhtml += `<div style="padding-left:90px;padding-top:50px;padding-bottom:40px;width:970;height:1320;"><p style="border:2px solid black;text-align:center;line-height:50px;width:790px;height:50px;background-color:${mycolor};"><strong>${header}</strong></p>`;
      }
      for (let jj = 0; jj < 3; jj++) {
        innerhtml += `<div style="display:inline-block;"><div style="border:2px solid black;height:400px;width:350px;margin-right:90px;margin-bottom:10px;display:flex;flex-direction:column;justify-content:center;background-color:${mycolor};"><p style="text-align:left;height:25px;line-height:25px;color:black;padding-left:25px;padding-bottom:10px;">${
          this.state.text
        }</p><div style="margin-left:10px;width:330px;display:grid;grid-template-columns:repeat(5,1fr);grid-template-rows:repeat(6,1fr);">`;

        for (let j = 0; j < shuffle.length; j++) {
          innerhtml += `<div style="outline:1px solid black;text-align:center;align-items:center;color:black;line-height:40px;height:40px;background-color:white;">${
            shuffle[j]
          }</div>`;
        }
        shuffle = this.shufflecard();
        innerhtml += `</div><p style="text-align:right;color:black;padding-right:20px;padding-top:10px;height:15px;">www.bingoport.co.uk</p></div></div>`;

        innerhtml += `<div style="display:inline-block;"><div style="border:2px solid black;height:400px;width:350px;margin-right:90px;margin-bottom:10px;display:flex;flex-direction:column;justify-content:center;background-color:${mycolor};"><p style="text-align:left;height:25px;line-height:25px;color:black;padding-left:25px;padding-bottom:10px;">${
          this.state.text
        }</p><div style="margin-left:10px;width:330px;display:grid;grid-template-columns:repeat(5,1fr);grid-template-rows:repeat(6,1fr);">`;

        for (let j = 0; j < shuffle.length; j++) {
          innerhtml += `<div style="outline:1px solid black;text-align:center;align-items:center;color:black;line-height:40px;height:40px;background-color:white;">${
            shuffle[j]
          }</div>`;
        }
        shuffle = this.shufflecard();
        innerhtml += `</div><p style="text-align:right;color:black;padding-right:20px;padding-top:10px;height:15px;">www.bingoport.co.uk</p></div></div>`;
      }
      innerhtml += `</div>`;
    }
    innerhtml += `</div>`;
    var WinPrint = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    );
    WinPrint.document.write(innerhtml);
    WinPrint.document.close();
    WinPrint.onload = () => {
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    };
  };

  render() {
    const pages = {};
    const sty = { background: this.state.color };
    const letters = this.state.bingo.map((a) => (
      <div className="card_number_item">{a}</div>
    ));
    return (
      <div className="container">
        <Header />
        <div className="mainContentPart">
          <div className="mainContentInner cardGenerationMain">
            <div className="t75_rightPart">
              <div className="cardGenerationBack" style={sty}>
                <div className="t75_cardBody">{letters}</div>
              </div>
            </div>
            <div className="t75_leftPart">
              <input
                type="text"
                onChange={this.handleChange}
                name="header"
                className="classInput"
                placeholder="Your header goes here"
              />
              <input
                type="text"
                onChange={this.handleChange}
                name="text"
                className="classInput"
                placeholder="Your text goes here"
              />
              <div className="pageNumberPart">
                <span className="pages">Pages:</span>
                <input
                  type="text"
                  name="num"
                  className="totalCardInput"
                  defaultValue="1"
                />
                <span className="cardNumber">* 6 Cards</span>
              </div>
              <button
                className="btn primaryButton generateCardBtn"
                type="button"
                onClick={this.handlePrint}
              >
                Click here to generate bingo card
              </button>
            </div>
            <div className="color_picker">
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#ff2dff" }}
                id="#ff2dff"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#ce23d4" }}
                id="#ce23d4"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#ff8e02" }}
                id="#ff8e02"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#ff5c01" }}
                id="#ff5c01"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#c70102" }}
                id="#c70102"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#5b0000" }}
                id="#5b0000"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#7dfb01" }}
                id="#7dfb01"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#3cb371" }}
                id="#3cb371"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#015b01" }}
                id="#015b01"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#01c5ff" }}
                id="#01c5ff"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#0090ff" }}
                id="#0090ff"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#012d5c" }}
                id="#012d5c"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#01c5ff" }}
                id="#01c5ff"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#01ffcd" }}
                id="#01ffcd"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#620203" }}
                id="#620203"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#dddb0a" }}
                id="#dddb0a"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#e2a001" }}
                id="#e2a001"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#69090a" }}
                id="#69090a"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
