import React, { Component } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Menu from "./components/Menu";
import Print75 from "./components/Print75";
import Print90 from "./components/Print90";
import Game75 from "./components/Game75";
import Game90 from "./components/Game90";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.css";



class App extends Component {
  constructor() {
    super();
    this.state = { color: "mediumorchid" };
  }

  handleColor = someColor => {
    console.log({ someColor });
    this.setState({ color: someColor });
  };

  render() {
    const color = this.state.color;
    return (
      <div className="app">
        <React.Fragment>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <div>
                  <Menu p={color} m={this.handleColor} />
                </div>
              )}
            />
            <Route path="/cards75" render={() => <Print75 />} />
            <Route path="/cards90" render={() => <Print90 />} />
            <Route path="/game75" render={() => <Game75 p={color} />} />
            <Route path="/game90" render={() => <Game90 p={color} />} />
          </Switch>
        </React.Fragment>
      </div>
    );
  }
}

export default App;
