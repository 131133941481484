import React, { Component } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Ball from "./Ball";
import "../App.css";
import table_back from '../images/table_back.png';
import table_back_active from '../images/table_back_active.png';
export default class Ballswitch90 extends Component {
  constructor(props) {
    super(props);
    // console.log({props});
    this.state = { balls: this.props.b, color: this.props.p };

  }

  componentDidMount() {
    if (sessionStorage.getItem("b90")) {
      console.log("session");
      this.setState({
        balls: JSON.parse(sessionStorage.getItem("b90"))
      });
    }
  }

  // shouldComponentUpdate() {
  //   let checker = this.state.balls.filter((a, i) => { a !== this.props.b[i] }).length
  //   if (checker > 0 || this.state.color !== this.props.p) {
  //     this.setState({
  //       balls: this.props.b,
  //       color: this.props.p
  //     })
  //   }
  // }

  render() {
    const color = { backgroundColor: this.state.color };
    let balls;
    if(sessionStorage.getItem("b90")) {

      balls = JSON.parse(sessionStorage.getItem("b90")).map((a, i) =>
        !a ? (
          <div className="ballh t90_body_item" key={i} style={{ backgroundImage: "url(" + table_back + ")" }} >
            <div className="ballcont">
              <div className="aball ">{i + 1}</div>
            </div>
          </div>
        ) : (
          <div className="ballh t90_body_item"  key={i} style={{ backgroundImage: "url(" + table_back_active + ")" }}  >
            <div className="ballcont">
              <div className="aballred ">{i + 1}</div>
            </div>
          </div>
        )
      );
    }
    else {
      balls = this.state.balls.map((a, i) =>
        !a ? (
          <div className="ballh t90_body_item" key={i} style={{ backgroundImage: "url(" + table_back + ")" }} >
            <div className="ballcont">
              <div className="aball ">{i + 1}</div>
            </div>
          </div>
        ) : (
          <div className="ballh t90_body_item"  key={i} style={{ backgroundImage: "url(" + table_back_active + ")" }} >
            <div className="ballcont">
              <div className="aballred ">{i + 1}</div>
            </div>
          </div>
        )
      );
    }
    return (
      // style={color}
      <div  className="ballholder">
        {balls}
      </div>
    );
  }
}
