// const cdn = process.env.REACT_APP_CDN;
const cdn = 'https://cdn.zentrl.io'; // Testing to see if it will work from Google Run service if hard coded

export const myaudio = new Audio(`${cdn}/audio/bingo/pong.mp3`);
export const g901 = new Audio(`${cdn}/audio/bingo/90-ball/1.mp3`);
export const g902 = new Audio(`${cdn}/audio/bingo/90-ball/2.mp3`);
export const g903 = new Audio(`${cdn}/audio/bingo/90-ball/3.mp3`);
export const g904 = new Audio(`${cdn}/audio/bingo/90-ball/4.mp3`);
export const g905 = new Audio(`${cdn}/audio/bingo/90-ball/5.mp3`);
export const g906 = new Audio(`${cdn}/audio/bingo/90-ball/6.mp3`);
export const g907 = new Audio(`${cdn}/audio/bingo/90-ball/7.mp3`);
export const g908 = new Audio(`${cdn}/audio/bingo/90-ball/8.mp3`);
export const g909 = new Audio(`${cdn}/audio/bingo/90-ball/9.mp3`);
export const g9010 = new Audio(`${cdn}/audio/bingo/90-ball/10.mp3`);
export const g9011 = new Audio(`${cdn}/audio/bingo/90-ball/11.mp3`);
export const g9012 = new Audio(`${cdn}/audio/bingo/90-ball/12.mp3`);
export const g9013 = new Audio(`${cdn}/audio/bingo/90-ball/13.mp3`);
export const g9014 = new Audio(`${cdn}/audio/bingo/90-ball/14.mp3`);
export const g9015 = new Audio(`${cdn}/audio/bingo/90-ball/15.mp3`);
export const g9016 = new Audio(`${cdn}/audio/bingo/90-ball/16.mp3`);
export const g9017 = new Audio(`${cdn}/audio/bingo/90-ball/17.mp3`);
export const g9018 = new Audio(`${cdn}/audio/bingo/90-ball/18.mp3`);
export const g9019 = new Audio(`${cdn}/audio/bingo/90-ball/19.mp3`);
export const g9020 = new Audio(`${cdn}/audio/bingo/90-ball/20.mp3`);
export const g9021 = new Audio(`${cdn}/audio/bingo/90-ball/21.mp3`);
export const g9022 = new Audio(`${cdn}/audio/bingo/90-ball/22.mp3`);
export const g9023 = new Audio(`${cdn}/audio/bingo/90-ball/23.mp3`);
export const g9024 = new Audio(`${cdn}/audio/bingo/90-ball/24.mp3`);
export const g9025 = new Audio(`${cdn}/audio/bingo/90-ball/25.mp3`);
export const g9026 = new Audio(`${cdn}/audio/bingo/90-ball/26.mp3`);
export const g9027 = new Audio(`${cdn}/audio/bingo/90-ball/27.mp3`);
export const g9028 = new Audio(`${cdn}/audio/bingo/90-ball/28.mp3`);
export const g9029 = new Audio(`${cdn}/audio/bingo/90-ball/29.mp3`);
export const g9030 = new Audio(`${cdn}/audio/bingo/90-ball/30.mp3`);
export const g9031 = new Audio(`${cdn}/audio/bingo/90-ball/31.mp3`);
export const g9032 = new Audio(`${cdn}/audio/bingo/90-ball/32.mp3`);
export const g9033 = new Audio(`${cdn}/audio/bingo/90-ball/33.mp3`);
export const g9034 = new Audio(`${cdn}/audio/bingo/90-ball/34.mp3`);
export const g9035 = new Audio(`${cdn}/audio/bingo/90-ball/35.mp3`);
export const g9036 = new Audio(`${cdn}/audio/bingo/90-ball/36.mp3`);
export const g9037 = new Audio(`${cdn}/audio/bingo/90-ball/37.mp3`);
export const g9038 = new Audio(`${cdn}/audio/bingo/90-ball/38.mp3`);
export const g9039 = new Audio(`${cdn}/audio/bingo/90-ball/39.mp3`);
export const g9040 = new Audio(`${cdn}/audio/bingo/90-ball/40.mp3`);
export const g9041 = new Audio(`${cdn}/audio/bingo/90-ball/41.mp3`);
export const g9042 = new Audio(`${cdn}/audio/bingo/90-ball/42.mp3`);
export const g9043 = new Audio(`${cdn}/audio/bingo/90-ball/43.mp3`);
export const g9044 = new Audio(`${cdn}/audio/bingo/90-ball/44.mp3`);
export const g9045 = new Audio(`${cdn}/audio/bingo/90-ball/45.mp3`);
export const g9046 = new Audio(`${cdn}/audio/bingo/90-ball/46.mp3`);
export const g9047 = new Audio(`${cdn}/audio/bingo/90-ball/47.mp3`);
export const g9048 = new Audio(`${cdn}/audio/bingo/90-ball/48.mp3`);
export const g9049 = new Audio(`${cdn}/audio/bingo/90-ball/49.mp3`);
export const g9050 = new Audio(`${cdn}/audio/bingo/90-ball/50.mp3`);
export const g9051 = new Audio(`${cdn}/audio/bingo/90-ball/51.mp3`);
export const g9052 = new Audio(`${cdn}/audio/bingo/90-ball/52.mp3`);
export const g9053 = new Audio(`${cdn}/audio/bingo/90-ball/53.mp3`);
export const g9054 = new Audio(`${cdn}/audio/bingo/90-ball/54.mp3`);
export const g9055 = new Audio(`${cdn}/audio/bingo/90-ball/55.mp3`);
export const g9056 = new Audio(`${cdn}/audio/bingo/90-ball/56.mp3`);
export const g9057 = new Audio(`${cdn}/audio/bingo/90-ball/57.mp3`);
export const g9058 = new Audio(`${cdn}/audio/bingo/90-ball/58.mp3`);
export const g9059 = new Audio(`${cdn}/audio/bingo/90-ball/59.mp3`);
export const g9060 = new Audio(`${cdn}/audio/bingo/90-ball/60.mp3`);
export const g9061 = new Audio(`${cdn}/audio/bingo/90-ball/61.mp3`);
export const g9062 = new Audio(`${cdn}/audio/bingo/90-ball/62.mp3`);
export const g9063 = new Audio(`${cdn}/audio/bingo/90-ball/63.mp3`);
export const g9064 = new Audio(`${cdn}/audio/bingo/90-ball/64.mp3`);
export const g9065 = new Audio(`${cdn}/audio/bingo/90-ball/65.mp3`);
export const g9066 = new Audio(`${cdn}/audio/bingo/90-ball/66.mp3`);
export const g9067 = new Audio(`${cdn}/audio/bingo/90-ball/67.mp3`);
export const g9068 = new Audio(`${cdn}/audio/bingo/90-ball/68.mp3`);
export const g9069 = new Audio(`${cdn}/audio/bingo/90-ball/69.mp3`);
export const g9070 = new Audio(`${cdn}/audio/bingo/90-ball/70.mp3`);
export const g9071 = new Audio(`${cdn}/audio/bingo/90-ball/71.mp3`);
export const g9072 = new Audio(`${cdn}/audio/bingo/90-ball/72.mp3`);
export const g9073 = new Audio(`${cdn}/audio/bingo/90-ball/73.mp3`);
export const g9074 = new Audio(`${cdn}/audio/bingo/90-ball/74.mp3`);
export const g9075 = new Audio(`${cdn}/audio/bingo/90-ball/75.mp3`);
export const g9076 = new Audio(`${cdn}/audio/bingo/90-ball/76.mp3`);
export const g9077 = new Audio(`${cdn}/audio/bingo/90-ball/77.mp3`);
export const g9078 = new Audio(`${cdn}/audio/bingo/90-ball/78.mp3`);
export const g9079 = new Audio(`${cdn}/audio/bingo/90-ball/79.mp3`);
export const g9080 = new Audio(`${cdn}/audio/bingo/90-ball/80.mp3`);
export const g9081 = new Audio(`${cdn}/audio/bingo/90-ball/81.mp3`);
export const g9082 = new Audio(`${cdn}/audio/bingo/90-ball/82.mp3`);
export const g9083 = new Audio(`${cdn}/audio/bingo/90-ball/83.mp3`);
export const g9084 = new Audio(`${cdn}/audio/bingo/90-ball/84.mp3`);
export const g9085 = new Audio(`${cdn}/audio/bingo/90-ball/85.mp3`);
export const g9086 = new Audio(`${cdn}/audio/bingo/90-ball/86.mp3`);
export const g9087 = new Audio(`${cdn}/audio/bingo/90-ball/87.mp3`);
export const g9088 = new Audio(`${cdn}/audio/bingo/90-ball/88.mp3`);
export const g9089 = new Audio(`${cdn}/audio/bingo/90-ball/89.mp3`);
export const g9090 = new Audio(`${cdn}/audio/bingo/90-ball/90.mp3`);
export const g751 = new Audio(`${cdn}/audio/bingo/75-ball/1.mp3`);
export const g752 = new Audio(`${cdn}/audio/bingo/75-ball/2.mp3`);
export const g753 = new Audio(`${cdn}/audio/bingo/75-ball/3.mp3`);
export const g754 = new Audio(`${cdn}/audio/bingo/75-ball/4.mp3`);
export const g755 = new Audio(`${cdn}/audio/bingo/75-ball/5.mp3`);
export const g756 = new Audio(`${cdn}/audio/bingo/75-ball/6.mp3`);
export const g757 = new Audio(`${cdn}/audio/bingo/75-ball/7.mp3`);
export const g758 = new Audio(`${cdn}/audio/bingo/75-ball/8.mp3`);
export const g759 = new Audio(`${cdn}/audio/bingo/75-ball/9.mp3`);
export const g7510 = new Audio(`${cdn}/audio/bingo/75-ball/10.mp3`);
export const g7511 = new Audio(`${cdn}/audio/bingo/75-ball/11.mp3`);
export const g7512 = new Audio(`${cdn}/audio/bingo/75-ball/12.mp3`);
export const g7513 = new Audio(`${cdn}/audio/bingo/75-ball/13.mp3`);
export const g7514 = new Audio(`${cdn}/audio/bingo/75-ball/14.mp3`);
export const g7515 = new Audio(`${cdn}/audio/bingo/75-ball/15.mp3`);
export const g7516 = new Audio(`${cdn}/audio/bingo/75-ball/16.mp3`);
export const g7517 = new Audio(`${cdn}/audio/bingo/75-ball/17.mp3`);
export const g7518 = new Audio(`${cdn}/audio/bingo/75-ball/18.mp3`);
export const g7519 = new Audio(`${cdn}/audio/bingo/75-ball/19.mp3`);
export const g7520 = new Audio(`${cdn}/audio/bingo/75-ball/20.mp3`);
export const g7521 = new Audio(`${cdn}/audio/bingo/75-ball/21.mp3`);
export const g7522 = new Audio(`${cdn}/audio/bingo/75-ball/22.mp3`);
export const g7523 = new Audio(`${cdn}/audio/bingo/75-ball/23.mp3`);
export const g7524 = new Audio(`${cdn}/audio/bingo/75-ball/24.mp3`);
export const g7525 = new Audio(`${cdn}/audio/bingo/75-ball/25.mp3`);
export const g7526 = new Audio(`${cdn}/audio/bingo/75-ball/26.mp3`);
export const g7527 = new Audio(`${cdn}/audio/bingo/75-ball/27.mp3`);
export const g7528 = new Audio(`${cdn}/audio/bingo/75-ball/28.mp3`);
export const g7529 = new Audio(`${cdn}/audio/bingo/75-ball/29.mp3`);
export const g7530 = new Audio(`${cdn}/audio/bingo/75-ball/30.mp3`);
export const g7531 = new Audio(`${cdn}/audio/bingo/75-ball/31.mp3`);
export const g7532 = new Audio(`${cdn}/audio/bingo/75-ball/32.mp3`);
export const g7533 = new Audio(`${cdn}/audio/bingo/75-ball/33.mp3`);
export const g7534 = new Audio(`${cdn}/audio/bingo/75-ball/34.mp3`);
export const g7535 = new Audio(`${cdn}/audio/bingo/75-ball/35.mp3`);
export const g7536 = new Audio(`${cdn}/audio/bingo/75-ball/36.mp3`);
export const g7537 = new Audio(`${cdn}/audio/bingo/75-ball/37.mp3`);
export const g7538 = new Audio(`${cdn}/audio/bingo/75-ball/38.mp3`);
export const g7539 = new Audio(`${cdn}/audio/bingo/75-ball/39.mp3`);
export const g7540 = new Audio(`${cdn}/audio/bingo/75-ball/40.mp3`);
export const g7541 = new Audio(`${cdn}/audio/bingo/75-ball/41.mp3`);
export const g7542 = new Audio(`${cdn}/audio/bingo/75-ball/42.mp3`);
export const g7543 = new Audio(`${cdn}/audio/bingo/75-ball/43.mp3`);
export const g7544 = new Audio(`${cdn}/audio/bingo/75-ball/44.mp3`);
export const g7545 = new Audio(`${cdn}/audio/bingo/75-ball/45.mp3`);
export const g7546 = new Audio(`${cdn}/audio/bingo/75-ball/46.mp3`);
export const g7547 = new Audio(`${cdn}/audio/bingo/75-ball/47.mp3`);
export const g7548 = new Audio(`${cdn}/audio/bingo/75-ball/48.mp3`);
export const g7549 = new Audio(`${cdn}/audio/bingo/75-ball/49.mp3`);
export const g7550 = new Audio(`${cdn}/audio/bingo/75-ball/50.mp3`);
export const g7551 = new Audio(`${cdn}/audio/bingo/75-ball/51.mp3`);
export const g7552 = new Audio(`${cdn}/audio/bingo/75-ball/52.mp3`);
export const g7553 = new Audio(`${cdn}/audio/bingo/75-ball/53.mp3`);
export const g7554 = new Audio(`${cdn}/audio/bingo/75-ball/54.mp3`);
export const g7555 = new Audio(`${cdn}/audio/bingo/75-ball/55.mp3`);
export const g7556 = new Audio(`${cdn}/audio/bingo/75-ball/56.mp3`);
export const g7557 = new Audio(`${cdn}/audio/bingo/75-ball/57.mp3`);
export const g7558 = new Audio(`${cdn}/audio/bingo/75-ball/58.mp3`);
export const g7559 = new Audio(`${cdn}/audio/bingo/75-ball/59.mp3`);
export const g7560 = new Audio(`${cdn}/audio/bingo/75-ball/60.mp3`);
export const g7561 = new Audio(`${cdn}/audio/bingo/75-ball/61.mp3`);
export const g7562 = new Audio(`${cdn}/audio/bingo/75-ball/62.mp3`);
export const g7563 = new Audio(`${cdn}/audio/bingo/75-ball/63.mp3`);
export const g7564 = new Audio(`${cdn}/audio/bingo/75-ball/64.mp3`);
export const g7565 = new Audio(`${cdn}/audio/bingo/75-ball/65.mp3`);
export const g7566 = new Audio(`${cdn}/audio/bingo/75-ball/66.mp3`);
export const g7567 = new Audio(`${cdn}/audio/bingo/75-ball/67.mp3`);
export const g7568 = new Audio(`${cdn}/audio/bingo/75-ball/68.mp3`);
export const g7569 = new Audio(`${cdn}/audio/bingo/75-ball/69.mp3`);
export const g7570 = new Audio(`${cdn}/audio/bingo/75-ball/70.mp3`);
export const g7571 = new Audio(`${cdn}/audio/bingo/75-ball/71.mp3`);
export const g7572 = new Audio(`${cdn}/audio/bingo/75-ball/72.mp3`);
export const g7573 = new Audio(`${cdn}/audio/bingo/75-ball/73.mp3`);
export const g7574 = new Audio(`${cdn}/audio/bingo/75-ball/74.mp3`);
export const g7575 = new Audio(`${cdn}/audio/bingo/75-ball/75.mp3`);
