import React, { Component } from 'react';
import { Switch, Route, Redirect, Link } from "react-router-dom"
import '../App.css'

export default class Ball extends Component {
    constructor(props) {
        super(props)
        this.state = {
            num:props.p.num,
            clicked:props.p.clicked
        }
    }

    render() {
        console.log("a ball", this.state.num, this.state.clicked)
        return (
            <div className="ballcont">
                {this.state.clicked
                ?
                (<div className="aball">{this.state.num}</div>)
                :
                (<div className="aballred">{this.state.num}</div>)
            }
            </div>
        )
    }
}