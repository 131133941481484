import React, { Component } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Ballswitch90 from "./Ballswitch90";
import Footer from "./Footer";
import * as myaudio from "./sounds.js";
import "../App.css";
import { truncate } from "fs";
import GetStateImage from "./GetStateImage";
import logo from '../images/logo.png';
import bingo_play from '../images/bingo_play.png';
import number_back from '../images/number_back.png';
import Header from './Header';

let words = `Kelly's Eye
Baby's Done It
I'm Free
Knock at the Door
Jack's Alive
Chopsticks
Lucky Seven
Garden Gate
Doctor's Orders
Tony's Den
Legs Eleven
Monkey's Cousin
Unlucky for Some
Valentine's Day
Young and Keen
Sweet Sixteen
Dancing Queen
Key of the Door
Goodbye Teens
Getting Plenty
Coming of Age
Two Little Ducks
Thee and Me
Two Dozen
Duck and Dive
Bed and Breakfast
Little Duck with a Crutch
In a state
Rise and Shine
Dirty Gertie
Get up and Run
Buckle my Shoe
Dirty Knees
Ask for More
Jump and Jive
Three Dozen
More than Eleven
Three and Eight
(Thos Famous) Steps
Life begins at Forty
Time for Fun
Famous Street in Manhattan
Down on your Knees
Droopy Drawers
Halfway There
Up to Tricks
Four and Seven
Four Dozen
PC 49
Bull's Eye
I love my mum
Danny La Rue
Stuck in the Tree77
Clean the Floor
Snakes Alive7
Five and Six
Heinz Varieties
Make them Wait
Brighton Line
Three Score
Baker's Bun
Turn of the Screw
Tickle Me
When I'm Sixty-Four
Old Age Pension
Clickety-Click
Made in Heaven
Saving Grace
Your place or mine
Three Score and Ten
Bang on a Drum
Par for the Course
Queen Bee
Candy Store
Strive and Strive
Was she worth it?
Sunset Strip
Heaven's Gate
One More Time
Blind Eighty
Stop and Run
Straight on Through
Time for Tea
Seven Dozen
Staying Alive
Between the Sticks
Torquay in Devon
Two Fat Ladies
Nearly There
Top of the House`;
words = words.split("\n");

export default class Game90 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balls: new Array(90).fill(false),
      color: this.props.p || "mediumorchid",
      ballnum: "",
      play: "",
      game: false,
      gameauto: false,
      nextauto: false,
      auto: 0,
      showBurst: "hidden",
      displayWord: "",
      centerburst: "",
      centerburstcolor: "white",
      centerburstcolor2: "black",
      centerburstcolor3: "white",
      msg: 'Select "Auto Play" or "Manual Play" to start playing',
      timer: 5,
      textcolor: "white"
    };

  }

  componentDidMount() {

    if (sessionStorage.getItem("b90")) {
      this.setState({
        balls: JSON.parse(sessionStorage.getItem("b90"))
      });
    } else {
      sessionStorage.setItem("b90", JSON.stringify(new Array(90).fill(false)));
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  midpong = () => {
    clearInterval(this.interval);
    if (this.state.showBurst === "hidden") {
      return;
    } else {
      myaudio.myaudio.play();
    }
    this.setState({
      centerburstcolor: "white",
      centerburstcolor2: "white",
      centerburstcolor3: "red"
    });
  };

  centerreset = () => {
    this.setState({
      centerburstcolor: "red",
      centerburstcolor2: "black",
      centerburstcolor3: "white"
    });
    this.interval = setInterval(() => {
      if (this.state.centerburstcolor === "red")
        this.setState({ centerburstcolor: "white" });
      else this.setState({ centerburstcolor: "red" });
    }, 500);
  };

  burst = () => {
    this.setState(state => ({
      centerburst: "\u00A0\u00A0Get\u00A0\u00A0 Next Ball"
    }));

    this.setState(state => ({
      centerburst: "Start Game"
    }));

    this.setState(state => ({
      centerburst: "Pause Game"
    }));
  };

  newGame = () => {
    clearInterval(this.interval);
    clearInterval(this.state.settime);
    this.setState(state => ({
      ballnum: "",
      play: "",
      game: false,
      msg: 'Select "Auto Play" or "Manual Play" to start playing',
      nextauto: false,
      auto: 0,
      showBurst: "hidden",
      centerburst: "",
      autoing: false
    }));
    sessionStorage.setItem("b90", JSON.stringify(new Array(90).fill(false)));
  };

  manual = () => {
    clearInterval(this.interval);
    clearInterval(this.state.settime);

    this.setState(state => ({
      game: true,
      msg: `Click "Get Next Ball" to continue
            
            Click "Auto play" for Auto Play
            
            
            
            Game Status: Manual Play`,
      nextauto: false,
      auto: 0,
      centerburst: "  Get   Next Ball",
      showBurst: "initial",
      autoing: false
    }));
    this.interval = setInterval(() => {
      if (this.state.centerburstcolor === "red")
        this.setState({ centerburstcolor: "white" });
      else this.setState({ centerburstcolor: "red" });
    }, 500);
  };

  auto = () => {
    clearInterval(this.interval);
    this.setState(state => ({
      game: true,
      msg: `Click "Start Game" to start playing
            
            
            
            
            
            Game Status: Auto playing`,
      nextauto: true,
      centerburst: "Start Game",
      auto: 5,
      showBurst: "initial",
      autoing: true
    }));
    this.interval = setInterval(() => {
      if (this.state.centerburstcolor === "red")
        this.setState({ centerburstcolor: "white" });
      else this.setState({ centerburstcolor: "red" });
    }, 500);
  };

  pong = () => {
    myaudio.myaudio.play();
  };

  componentWillUnmount = () => {
    this.newGame()
  }

  autoBall = () => {
    let ballstemp = this.state.balls;
    if (ballstemp.indexOf(false) === -1) {
      return;
    }
    let rando;
    do {
      rando = Math.floor(Math.random() * 90);
    } while (ballstemp[rando] === true && ballstemp.indexOf(false) !== -1);
    console.log(rando + 1);
    ballstemp[rando] = true;
    this.setState(state => ({
      balls: [...ballstemp],
      ballnum: rando + 1,
      displayWord: words[rando]
    }));
    myaudio[`g90${rando + 1}`].play();
    sessionStorage.setItem("b90", JSON.stringify(ballstemp));
    // this.forceUpdate();
  };

  getBall = () => {
    // console.log(this.state)
    console.log("getball");
    if (this.state.centerburst === "hidden") {
      console.log("hidden");
      return;
    }
    if (this.state.centerburst === "Start Game") {
        this.setState({
          settime: setInterval(this.autoBall, this.state.timer * 1000),
          autoing: true,
          centerburst: "Pause Game"
        });
    } else if (this.state.centerburst === "Pause Game") {
      clearInterval(this.state.settime);
      this.setState({ autoing: true, centerburst: "Start Game" });
    } else {
      let ballstemp = this.state.balls;
      if (ballstemp.indexOf(false) === -1) {
        return;
      }
      let rando;
      do {
        rando = Math.floor(Math.random() * 90);
      } while (ballstemp[rando] === true && ballstemp.indexOf(false) !== -1);
      console.log(rando + 1);
      ballstemp[rando] = true;

      this.setState(state => ({
        balls: [...ballstemp],
        ballnum: rando + 1,
        displayWord: words[rando]
      }));
      myaudio[`g90${rando + 1}`].play();
      sessionStorage.setItem("b90", JSON.stringify(ballstemp));
      this.forceUpdate();
    }
  };

  render() {
    // console.log("render200", this.state.balls);
    const displayball = this.state.ballnum;
    const color = { backgroundColor: this.state.color };
    const border = { borderBottomColor: this.state.color };
    const border2 = { visibility: this.state.showBurst };
    const border3 = { borderBottomColor: this.state.centerburstcolor2 };
    const border4 = { borderBottomColor: this.state.centerburstcolor3 };
    return (
    <div className="container">
      <Header/>
      <div className="mainContentPart">
        <div className="mainContentInner">
          <div className="leftMainContentPart">
            <div className="buttonsPart">
              <button className="btn primaryButton" type="button" onClick={this.newGame} onMouseEnter={this.pong}>Start New Game</button>
              <button className="btn primaryButton middleButton" type="button" onClick={this.manual} onMouseEnter={this.pong} >Manual Play</button>
              <button className="btn primaryButton autoPlaybtn" type="button" onClick={this.auto} onMouseEnter={this.pong} >Auto Play</button>

              {this.state.autoing ? <div className='timercont'>
                    <input onChange={this.handleChange} className="timer" type="number" name="timer" value={this.state.timer} />
                    {/* <p className="timer" style={{ color: "black" }}>
                      seconds
                    </p> */}
                  </div> : <div/>}
                <div className="goBackButtonPart">
                  <Link to="/" className="back">
                    <button className="btn secondayButton" type="button">Go Back</button>
                  </Link>
                </div>
              
            </div>
            <div className="leftContentPart">
              <div className="numberDisplayPart" style={{ backgroundImage: "url(" + number_back + ")" }}>
              {displayball}
              </div>
              <div className="nextBallButton">
              {(!this.state.showBurst !== "hidden" && this.state.game && !this.state.nextauto) ? <div onMouseEnter={this.midpong} onMouseLeave={this.centerreset} onClick={this.getBall} className="burstholds" style={{ color: this.state.centerburstcolor }}>
                <button className="btn primaryButton" type="button">{this.state.centerburst}</button>
              </div> :<div /> 
              }
                
              </div>
            </div>
          </div>
          <div className="center90ContentPart">
            {this.state.autoing ? 
              <GetStateImage settime={this.state.settime} centerburst={this.state.centerburst} onMouseEnter={this.midpong} onMouseLeave={this.centerreset}  onClick={this.getBall} />          
          : null}
          </div>
          <div className="rightContentPart right90Part">
            <div className="t90_body">
              <Ballswitch90 p={this.state.color} b={this.state.balls} />
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
