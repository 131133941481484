import React from "react";
import bingo_play from "../images/bingo_play.png";
import bingo_stop from "../images/bingo_stop.png";

const GetStateImage = (props) => {
  if (props.centerburst === "Start Game") {
    // For 75 : onMouseEnter={this.midpong} onMouseLeave={this.centerreset}  onClick={this.getBall}
    return (
      <img
        src={bingo_play}
        alt="Bingo Play"
        title="Bingo Play"
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClick}
      />
    );
  } else if (props.centerburst === "Pause Game") {
    return (
      <img
        src={bingo_stop}
        alt="Bingo Stop"
        title="Bingo Stop"
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClick}
      />
    );
  }
};
export default GetStateImage;
