import React, { Component } from 'react';
import { Switch, Route, Redirect, Link } from "react-router-dom"
import '../App.css'

export default class Ball75 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            num: props.p.num,
            clicked: props.p.clicked
        }
    }

    render() {
        return <div className="ballcont75">
            {this.state.clicked ? (
              <div className="aball75">{this.state.num}</div>
            ) : (
              <div className="aball75 aballred75">{this.state.num}</div>
            )}
          </div>;
    }
}