import React, { Component } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import "../App.css";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { clicked: false };
  }

  handleClick = () => {
    console.log("click");
    this.setState(state => ({
      clicked: !state.clicked
    }));
  };

  instprint = () => {
    var WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
    WinPrint.document.write(`<div><div>
    <h2 style="text-align:center;">BingoPort Instructions</h2
    <p>
                  <strong>Print and play</strong>
                     - run bingo games from the comfort of your home with our simulated Bingo Caller and printable Cards.
                </p>
                <p>There's no need for any specialist equipment with our DIY bingo accessories. Play alone, or host a great night in with family and friends.
                </p>
                <p>
                  <strong>
                    Print cards
                    </strong>
                </p>
                <ol>
                  <li>Choose between 75 and 90 Ball Bingo for the ultimate at-home experience. Select 'Print Cards' under your preference.</li>
                  <li>Customise your cards in the new window. Create a heading, choose a background colour (choose a white border to reduce your ink use!), and decide how many pages of cards you will need (with 6 cards to a page).</li>
                  <li>Finished designing your cards? Select 'Generate cards' and then 'Print' to see your designs come to life.</li>
                </ol>
                <p>
                  <strong>Play</strong>
                </p>
                <ol>
                  <li>75 or 90 Ball Bingo? Choose the option that matches your cards.</li>
                  <li>Select your player mode:</li>
                </ol>
                <p>
                  <strong>Manual</strong>
                </p>
                <p>This option gives you complete control over when each ball is released. Simply click 'next' to randomly select a ball.</p>
                <p>
                  <strong>Automatic</strong>
                </p>

                <p>Hand the reigns to the Bingo Caller for the ultimate professional experience, and balls will be selected on a timer basis. Stick to the default 5 second interval, or choose your preferred amount of time - then click 'Apply'.</p>  </div></div>`);
    WinPrint.document.close();
    WinPrint.onload = () => {
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    };
  };

  render() {
    // console.log(this.state.clicked);
    return (
      <div className="footerh">
        {this.state.clicked ? (
          <div className="footer">
            <h4 className="copy cent">
              © Bingoport.com 2019 All Rights Reserved
            </h4>
            <h3 onClick={this.handleClick} className="inst hov cent">
              Click here to show/hide instructions.
            </h3>
            <div className="inst2">
              <div className="instructions">
                <p>
                  <strong>{"Print and play"}</strong>
                  {
                    " - run bingo games from the comfort of your home with our simulated Bingo Caller and printable Cards."
                  }
                </p>
                <p>
                  {
                    "There's no need for any specialist equipment with our DIY bingo accessories. Play alone, or host a great night in with family and friends."
                  }
                </p>
                <p>
                  <strong>
                    {"Print cards"}
                    </strong>
                </p>
                <ol>
                  <li>
                    {
                      "Choose between 75 and 90 Ball Bingo for the ultimate at-home experience. Select 'Print Cards' under your preference."
                    }
                  </li>
                  <li>
                    {
                      "Customise your cards in the new window. Create a heading, choose a background colour (choose a white border to reduce your ink use!), and decide how many pages of cards you will need (with 6 cards to a page)."
                    }
                  </li>
                  <li>
                    {
                      "Finished designing your cards? Select 'Generate cards' and then 'Print' to see your designs come to life."
                    }
                  </li>
                </ol>
                <p>
                  <strong>{"Play"}</strong>
                </p>
                <ol>
                  <li>
                    {
                      "75 or 90 Ball Bingo? Choose the option that matches your cards."
                    }
                  </li>
                  <li>{"Select your player mode:"}</li>
                </ol>
                <p>
                  <strong>{"Manual"}</strong>
                </p>
                <p>
                  {
                    "This option gives you complete control over when each ball is released. Simply click 'next' to randomly select a ball."
                  }
                </p>
                <p>
                  <strong>{"Automatic"}</strong>
                </p>

                <p>
                  {
                    "Hand the reigns to the Bingo Caller for the ultimate professional experience, and balls will be selected on a timer basis. Stick to the default 5 second interval, or choose your preferred amount of time - then click 'Apply'."
                  }
                </p>
                <button onClick={this.instprint} className="printinst">
                  {"Print this Page"}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="footer2">
            <h4 className="copy cent">
              © Bingoport.com 2019 All Rights Reserved
            </h4>
            <h3 onClick={this.handleClick} className="inst hov cent">
              Click here to show/hide instructions.
            </h3>
          </div>
        )}
      </div>
    );
  }
}
