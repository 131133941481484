import React, { Component } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Footer from "./Footer";
import Header from './Header';

// import "../App.css";
// import "../mobile.css";

import logo from '../images/logo.png';

export default class Menu extends Component {
  
  constructor(props) {
    super(props);
    this.state = { color: this.props.p };
  }

  componentDidMount() {}

  handleColor = e => {
    console.log(e.target.id);
    this.setState({
      color: e.target.id
    });
    this.props.m(e.target.id)
  };

  render() {
    console.log("menu", this.state.color)
    const styles2 = {
      // color:'white',
      textDecoration: "none"
    };
    const clr = { color: this.state.color };
    const backclr = { background: this.state.color };
    // console.log(clr);
    const bor = { borderBottomColor: this.state.color };
    return (
      <div className="container">
      <Header/>
      <div className="mainContentPart">
        <div className="mainContentInner cardGenerationMain">
          <div className="main_page_part">
            <div className="main_page_inner">
              <h2>Select Type of <span className="txt_color" style={clr}>bingo</span> caller</h2>
              <div className="botttomPart">
                <div className="left75Part backGroundChange" style={backclr}>
                  <Link  to="/game75">
                    <div className="firstLine">75 balls</div>
                  </Link>
                  <Link  to="/cards75">
                    <div className="secondLine">Print Card</div>
                  </Link>
                </div>
                <div className="left90Part backGroundChange" style={backclr}>
                  <Link  to="/game90">
                    <div className="firstLine">90 balls</div>
                  </Link>
                  <Link  to="/cards90">
                    <div className="secondLine">Print Card</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="color_picker">
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#ff2dff"}} id="#ff2dff"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#ce23d4"}} id="#ce23d4"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#ff8e02"}} id="#ff8e02"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#ff5c01"}} id="#ff5c01"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#c70102"}} id="#c70102"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#5b0000"}} id="#5b0000"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#7dfb01"}} id="#7dfb01"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#3cb371"}} id="#3cb371"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#015b01"}} id="#015b01"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#01c5ff"}} id="#01c5ff"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#0090ff"}} id="#0090ff"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#012d5c"}} id="#012d5c"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#01c5ff"}} id="#01c5ff"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#01ffcd"}} id="#01ffcd"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#620203"}} id="#620203"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#dddb0a"}} id="#dddb0a"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#e2a001"}} id="#e2a001"></div>
            <div className="color_circle" onClick={this.handleColor} style={{backgroundColor: "#69090a"}} id="#69090a"></div>
          </div>
        </div>
      </div>
    </div>
      );
  }
}
