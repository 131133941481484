import React, { Component } from "react";
import "../App.css";

export default class ArrowU extends Component {
    constructor(props) {
        super(props);
        this.state = {
            msg: this.props.p
        }
    }

    render() {
        return (
            <div className="apparrow">
                <div className="print75white">
                    <div className="innerprint75">{this.state.msg}</div>
                </div>
                <div className="arrowh">
                    <div className="icono2 icono-arrow1"></div>
                </div>
            </div>
        );
    }
}
