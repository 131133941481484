import React, { Component } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Ballswitch75 from "./Ballswitch75";
import GetStateImage from "./GetStateImage";
import * as myaudio from "./sounds.js";
import Footer from "./Footer";
import "../App.css";
import logo from '../images/logo.png';
import bingo_play from '../images/bingo_play.png';
import bingo_stop from '../images/bingo_stop.png';
import number_back from '../images/number_back.png';
import Header from './Header';

export default class Game75 extends Component {

  constructor(props) {

    super(props);
    this.state = {
      color: this.props.p || "mediumorchid",
      settime:"",
      ballnum: "",
      play: "",
      game: false,
      gameauto: false,
      nextauto: false,
      auto: 0,
      centerburst: "",
      centerburstcolor: "white",
      centerburstcolor2: "black",
      centerburstcolor3: "white",
      showBurst: "hidden",
      msg: 'Select "Auto Play" or "Manual Play" to start playing',
      timer: 5,
      textcolor: "white",
      autoing:false,
      balls: new Array(75).fill(false)
    };

  }

  componentDidMount() {
    
    if (sessionStorage.getItem("b75")) {
      this.setState({
        balls: JSON.parse(sessionStorage.getItem("b75"))
      });
    } else {
      sessionStorage.setItem("b75", JSON.stringify(new Array(75).fill(false)));
    }
  }

  handleChange = e => {
    
    console.log(e.target.value)
    
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  midpong = () => {
    // alert("Hii");
    clearInterval(this.interval);
    if (this.state.showBurst === "hidden") {
      return;
    } else {
      myaudio.myaudio.play();
    }
    this.setState({
      centerburstcolor: "white",
      centerburstcolor2: "white",
      centerburstcolor3: "red"
    });
  };

  centerreset = () => {
    this.setState({
      centerburstcolor: "red",
      centerburstcolor2: "black",
      centerburstcolor3: "white"
    });
    this.interval = setInterval(() => {
      if (this.state.centerburstcolor === "red")
        this.setState({ centerburstcolor: "white" });
      else
        this.setState({
          centerburstcolor: "red"
        });
    }, 500);
  };

  pong = () => {
    // console.log(myaudio.myaudio.play())
    myaudio.myaudio.play();
  };

  componentWillUnmount = () => {
    this.newGame()
  }

  newGame = () => {
    
    clearInterval(this.interval);
    clearInterval(this.state.settime);
    this.setState(state => ({
      ballnum: "",
      play: "",
      game: false,
      msg: 'Select "Auto Play" or "Manual Play" to start playing',
      nextauto: false,
      auto: 0,
      showBurst: "hidden",
      centerburst: "",
      autoing:false
    }));
    sessionStorage.setItem("b75", JSON.stringify(new Array(75).fill(false)));
    
  };

  manual = () => {
    clearInterval(this.interval);
    clearInterval(this.state.settime);
    this.setState(state => ({
      game: true,
      msg: `Click "Get Next Ball" to continue.
            
            Click "Auto play" for Auto Play
            
            
            
            Game Status: Manual Play`,
      nextauto: false,
      auto: 0,
      centerburst: "  Get   Next Ball",
      showBurst: "initial",
      autoing: false
    }));
  };

  auto = () => {
    clearInterval(this.interval);
    clearInterval(this.state.settime);
    this.setState(state => ({
      game: true,
      msg: `Click "Start Game" to start playing
            
            
            
            
            
            Game Status: Auto playing`,
      nextauto: true,
      gameauto: true,
      autoing: true,
      centerburst: "Start Game",
      auto: 5,
      showBurst: "initial"
    }));
    this.interval = setInterval(() => {
      if (this.state.centerburstcolor === "red") this.setState({
        centerburstcolor: "white"
      });
      else this.setState({ centerburstcolor: "red" });
    }, 500);
  };

  autoBall = () => {
    let ballstemp = this.state.balls;
    if (ballstemp.indexOf(false) === -1) {
      return;
    }
    let rando;
    do {
      rando = Math.floor(Math.random() * 75);
    } while (ballstemp[rando] === true && ballstemp.indexOf(false) !== -1);
    let rando2 = rando + 1
    if (rando2 < 16) rando2 = "B" + rando2
    else if (rando2 < 31) rando2 = "I" + rando2
    else if (rando2 < 46) rando2 = "N" + rando2
    else if (rando2 < 61) rando2 = "G" + rando2
    else rando2 = "O" + rando2
    ballstemp[rando] = true;
    this.setState(state => ({
      balls: [...ballstemp],
      ballnum: rando2
    }));
    myaudio[`g75${rando + 1}`].play();
    sessionStorage.setItem("b75", JSON.stringify(ballstemp));
    // this.forceUpdate();
  };
  
  getBall = () => {
    // console.log(this.state)
    console.log("getball");
    if (this.state.centerburst === "hidden") {
      console.log("hidden");
      return;
    }
    if (this.state.centerburst === "Start Game") {
      this.setState({
        settime: setInterval(this.autoBall, this.state.timer * 1000),
        autoing: true,
        centerburst: "Pause Game"
      });
    } else if (this.state.centerburst === "Pause Game") {
      clearInterval(this.state.settime);
      this.setState({ autoing: true, centerburst: "Start Game" });
    } else {
      let ballstemp = this.state.balls;
      if (ballstemp.indexOf(false) === -1) {
        return;
      }
      let rando;
      do {
        rando = Math.floor(Math.random() * 75);
      } while (ballstemp[rando] === true && ballstemp.indexOf(false) !== -1);
      console.log(rando + 1);
      ballstemp[rando] = true;
      let rando2 = rando + 1
      if (rando2 < 16) rando2 = "B" + rando2
      else if (rando2 < 31) rando2 = "I" + rando2
      else if (rando2 < 46) rando2 = "N" + rando2
      else if (rando2 < 61) rando2 = "G" + rando2
      else rando2 = "O" + rando2
      this.setState(state => ({
        balls: [...ballstemp],
        ballnum: rando2
      }));
      myaudio[`g75${rando + 1}`].play();
      sessionStorage.setItem("b75", JSON.stringify(ballstemp));
      this.forceUpdate();
    }
  };

  // componentDidUpdate() {
  //   alert(this.state.ballnum);
  // }


  render() {
    const color = { backgroundColor: this.state.color };
    const border = { borderBottomColor: this.state.color };
    const border2 = { visibility: this.state.showBurst };
    const border3 = { borderBottomColor: this.state.centerburstcolor2 };
    const border4 = { borderBottomColor: this.state.centerburstcolor3 };
    const displayball = this.state.ballnum;
    // alert(displayball);
    return (
      <div className="container">
      <Header/>
      <div className="mainContentPart">
        <div className="mainContentInner">
          <div className="leftMainContentPart">
            <div className="buttonsPart">
              <button className="btn primaryButton" type="button" onClick={this.newGame} onMouseEnter={this.pong} >Start New Game</button>
              <button className="btn primaryButton middleButton" type="button" onClick={this.manual} onMouseEnter={this.pong} >Manual Play</button>
              <button className="btn primaryButton autoPlaybtn" type="button"  onClick={this.auto} onMouseEnter={this.pong} >Auto Play</button>
              {this.state.autoing ? 
                  <div className="timercont"> 
                    <input onChange={this.handleChange} className="timer" type="number" name="timer" value={this.state.timer} />
                  </div> : null}
              <div className="goBackButtonPart">
                <Link to="/" className="back">
                  <button className="btn secondayButton" type="button">Go Back</button>
                </Link>
              </div>
            </div>
            <div className="leftContentPart">
              <div className="numberDisplayPart" style={{ backgroundImage: "url(" + number_back + ")" }} >
              {displayball}
              </div>
              <div className="nextBallButton">
                {(!this.state.showBurst !== "hidden" && this.state.game && !this.state.nextauto) ?
                  <div onMouseEnter={this.midpong} onMouseLeave={this.centerreset} onClick={this.getBall} className="burstholds75" style={{ color: this.state.centerburstcolor }}>

                  <button className="btn primaryButton" type="button">{this.state.centerburst}</button>
                  </div>
                : 
                  <div />
              }
              </div>
            </div>
          </div>
          <div className="centerContentPart">
          {this.state.autoing ? 
             <GetStateImage settime={this.state.settime} centerburst={this.state.centerburst} onMouseEnter={this.midpong} onMouseLeave={this.centerreset}  onClick={this.getBall} />          
          : null}
          </div>
          <div className="rightContentPart">
            <div className="t75_table_title">
              <div className="t75_title_item">B</div>
              <div className="t75_title_item">I</div>
              <div className="t75_title_item">N</div>
              <div className="t75_title_item">G</div>
              <div className="t75_title_item">O</div>
            </div>
            <div className="t75_body">
              <Ballswitch75  p={color} b={this.state.balls} />
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
