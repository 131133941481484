import React, { Component } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Arrow from "./Arrow";
import ArrowU from "./ArrowU";
import "../App.css";
import logo from "../images/logo.png";
import Header from "./Header";

export default class Print90 extends Component {
  constructor() {
    super();
    this.state = {
      color: "#ff2dff",
      header: "",
      text: "",
      num: 1,
      bingo: new Array(27).fill(0),
      print: 1,
    };
  }

  rands = (nums, max) => {
    var keys = [],
      numbers = [],
      x,
      total = 0;
    while (total < nums) {
      x = Math.floor(Math.random() * +max);
      // way faster that looping through the array to check if it exists
      if (keys[x] === undefined) {
        keys[x] = 1;
        numbers.push(x);
        total++;
      }
    }
    return numbers;
  };

  shuffleCard = () => {
    //makes a bingo array to insert into card later
    let tb = [...this.state.bingo];
    // console.log(tb.length);
    let mynums = this.rands(5, 9);
    let nums2 = this.rands(5, 9);
    mynums = mynums.concat(nums2.map((a) => a + 9));
    nums2 = this.rands(5, 9);
    mynums = mynums.concat(nums2.map((a) => a + 18));
    // console.log(mynums);
    for (let i in tb) {
      if (mynums.includes(+i)) {
        let newnum = 0;
        if (+i % 9 === 0) {
          do {
            newnum = Math.ceil(Math.random() * 9);
          } while (tb.includes(newnum));
        } else if (+i % 9 < 8) {
          let gax = (+i % 9) * 10 + 10;
          do {
            newnum = Math.floor(Math.random() * 10 + gax - 10);
          } while (tb.includes(newnum));
        } else {
          do {
            newnum = Math.round(Math.random() * 10 + 80);
          } while (tb.includes(newnum));
        }
        tb[i] = newnum;
      } else {
        tb[i] = "";
      }
      // console.log(tb);
      this.setState({
        bingo: [...tb],
      });
    }
    return tb;
  };

  componentDidMount() {
    this.shuffleCard();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleColor = (e) => {
    // console.log(e.target.id);
    this.setState({
      color: e.target.id,
    });
  };

  makeBingo = (list) => {
    const letters2 = this.state.bingo.map((a) => (
      <div style="border:1px solid black;text-align:center;align-items:center;font-weight:bolder;line-height:calc(calc(19.9vw - 12px)/6);font-size:calc(calc(19.9vw - 12px)/9);height:calc(calc(19.9vw - 12px)/6);color:black;background:white;">
        {a}
      </div>
    ));
    var bingo = `
          <div style="position:relative;left:400px;">
            <div style="border:2px dashed black;background: white;width: 55vw;height: 25vw;display:flex;flex-direction:column;align-items:center;justify-content:center;">
              <div style=''>
                  ${this.state.header}
              </div>
              <div style=bnums22 >
                <p>${this.state.text}</p>
                <div bnums23>${letters2}</div>
                <div logo3>www.bingoport.co.uk</div>
              </div>
            </div>
          </div>`;
  };

  handlePrint = () => {
    // console.log("print");
    let header = this.state.header || "90 Ball Bingo";
    let mycolor = this.state.color;
    let nums = this.state.num;
    if (nums < 1) nums = 1;
    let innerhtml = `<div style="padding-top:50">`;
    let shuffle = [...this.state.bingo];
    for (let i = 0; i < nums; i++) {
      //for each page fill with 5 of each bingo
      if (i === nums - 1) {
        innerhtml += `<div style="padding-left:242px;margin-top:100px;padding-bottom:52px;width:970;"><p style="border:2px solid black;text-align:center;line-height:50px;width:485px;height:50px;background-color:${mycolor};"><strong>${header}</strong></p>`;
      } else {
        innerhtml += `<div style="padding-left:242px;margin-top:100px;padding-bottom:52px;width:970;height:1320;"><p style="border:2px solid black;text-align:center;line-height:50px;width:485px;height:50px;background-color:${mycolor};"><strong>${header}</strong></p>`;
      }
      for (let jj = 0; jj < 5; jj++) {
        innerhtml += `<div style="border:2px solid black;height:200px;width:485px;margin-bottom:20px;display:flex;flex-direction:column;justify-content:center;background-color:${mycolor}"><p style="text-align:left;line-height:25px;color:black;padding-left:25px;margin-bottom:15px;">${
          this.state.text
        }</p><div style="margin-left:10px;width:468px;display:grid;grid-template-columns:repeat(9,1fr);grid-template-rows:repeat(3,1fr);">`;

        for (let j = 0; j < shuffle.length; j++) {
          innerhtml += `<div style="outline:1px solid black;text-align:center;align-items:center;color:black;line-height:40px;height:40px;background-color:white;">${
            shuffle[j]
          }</div>`;
        }
        shuffle = this.shuffleCard();
        innerhtml += `</div><p style="text-align:right;color:black;padding-right:20px;margin-top:10px;">www.bingoport.co.uk</p></div>`;
      }
      innerhtml += `</div>`;
    }
    innerhtml += `</div>`;
    var WinPrint = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    );
    WinPrint.document.write(innerhtml);
    WinPrint.document.close();
    WinPrint.onload = () => {
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    };
  };

  render() {
    const pages = (
      <div title="pages" className="pp90">
        <img src="./bingoportlogo.png" alt="BingoPort.co.uk" height="250px" />
      </div>
    );
    const sty = { background: this.state.color };
    const letters = this.state.bingo.map((a) => (
      <div className="card_number_item">{a}</div>
    ));
    return (
      <div className="container">
        <Header />
        <div className="mainContentPart">
          <div className="mainContentInner cardGenerationMain">
            <div className="t90_rightPart">
              <div className="cardGenerationBack" style={sty}>
                <div className="t90_cardBody">{letters}</div>
              </div>
            </div>
            <div className="t75_leftPart">
              <input
                type="text"
                onChange={this.handleChange}
                name="header"
                className="classInput"
                placeholder="Your header goes here"
              />
              <input
                type="text"
                onChange={this.handleChange}
                name="text"
                className="classInput"
                placeholder="Your text goes here"
              />
              <div className="pageNumberPart">
                <span className="pages">Pages:</span>
                <input
                  type="text"
                  name="num"
                  className="totalCardInput"
                  defaultValue="1"
                  onChange={this.handleChange}
                />
                <span className="cardNumber">* 6 Cards</span>
              </div>
              <button
                className="btn primaryButton generateCardBtn"
                type="button"
                onClick={this.handlePrint}
              >
                Click here to generate bingo card
              </button>
            </div>
            <div className="color_picker">
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#ff2dff" }}
                id="#ff2dff"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#ce23d4" }}
                id="#ce23d4"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#ff8e02" }}
                id="#ff8e02"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#ff5c01" }}
                id="#ff5c01"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#c70102" }}
                id="#c70102"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#5b0000" }}
                id="#5b0000"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#7dfb01" }}
                id="#7dfb01"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#3cb371" }}
                id="#3cb371"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#015b01" }}
                id="#015b01"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#01c5ff" }}
                id="#01c5ff"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#0090ff" }}
                id="#0090ff"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#012d5c" }}
                id="#012d5c"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#01c5ff" }}
                id="#01c5ff"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#01ffcd" }}
                id="#01ffcd"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#620203" }}
                id="#620203"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#dddb0a" }}
                id="#dddb0a"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#e2a001" }}
                id="#e2a001"
              />
              <div
                onClick={this.handleColor}
                className="color_circle"
                style={{ backgroundColor: "#69090a" }}
                id="#69090a"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
