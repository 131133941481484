import React, { Component } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Ball75 from "./Ball75";
import "../App.css";
import table_back from '../images/table_back.png';
import table_back_active from '../images/table_back_active.png';



class Ballswitch75 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            balls: new Array(75).fill(false),
            color: this.props.p
        };

        // if (sessionStorage.getItem("b75")) {
        //   console.log("session");
        //   this.setState({
        //       balls: JSON.parse(sessionStorage.getItem("b75"))
        //   });
        // }

    }

    componentWillMount() {
      
      if (sessionStorage.getItem("b75")) {
          
        this.setState({
            balls: JSON.parse(sessionStorage.getItem("b75"))
        });
      }
    }

    render() {

      // const table_back_image={
      //   backgroundImage:table_back
      //  }

      // alert(sessionStorage.getItem("b75"))
        // alert(this.state.balls);
        // alert(sessionStorage.getItem("b75"));
        let balls;
        if(sessionStorage.getItem("b75")) {
           balls = JSON.parse(sessionStorage.getItem("b75")).map(
            (a, i) =>
            !a ? (
                <div className="ballh75 t75_body_item" style={{ backgroundImage: "url(" + table_back + ")" }} key={i}>
                  <div className="ballcont">
                    <div className="aball new_pad">{i + 1}</div>
                  </div>
                </div>
              ) : (
                <div className="ballh75 t75_body_item" style={{ backgroundImage: "url(" + table_back_active + ")" }} key={i}>
                  <div className="ballcont">
                    <div className="aballred new_pad">{i + 1}</div>
                  </div>
                </div>
              )
          );
        }
        else {
           balls = this.state.balls.map(
            (a, i) =>
            !a ? (
                <div className="ballh75 t75_body_item" style={{ backgroundImage: "url(" + table_back + ")" }} key={i}>
                  <div className="ballcont">
                    <div className="aball new_pad">{i + 1}</div>
                  </div>
                </div>
              ) : (
                <div className="ballh75 t75_body_item" style={{ backgroundImage: "url(" + table_back_active + ")" }} key={i}>
                  <div className="ballcont">
                    <div className="aballred new_pad">{i + 1}</div>
                  </div>
                </div>
              )
          );
        }

        return <div  className="ballholder75 t75_body">{balls}</div>;
    }
}

export default Ballswitch75;